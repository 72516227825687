import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ListingService } from '@app/listing/listing.service';
import { ListingInfo } from '@app/models/listing.model';
import { NotificationService } from '@app/services/notification.service';
import { SessionService } from '@app/services/session.service';
import { ShareOptionsComponent } from '@app/share-options/share-options.component';
import { ApiService } from '@services/api.service';
import { NetworkServiceService } from '@services/network-service.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxXml2jsonService } from 'ngx-xml2json';

@Component({
  selector: 'app-listing-qr-code',
  templateUrl: './listing-qr-code.component.html',
  styleUrls: ['./listing-qr-code.component.scss'],
})
export class ListingQrCodeComponent implements OnInit {
  veiwQRUrl: string = 'https://api.qrserver.com/v1/create-qr-code/?size=85x85&data=https://dev.myqlout.com/qtagQr/'
  publicList: any;  picUrl: any;  photos: any[] = [];  currentUrl: any;  ipAddress: any;  lat: any;  lng: any;
  objs: any;  deviceInfo: any;  user_id: any;  user: any;  addressHead: any;  apiIp: any;  address1: any;  address2: any;
  address3: any;  address4: any;  address5: any;  notPublish: boolean = false;  addedInCart: boolean = false;  title = 'QLOUT';
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild('myGoogleMap', { static: false })
  map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false })
  info!: MapInfoWindow;

  zoom = 12;  maxZoom = 20;  minZoom = 5;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,    scrollwheel: true,    disableDoubleClickZoom: false,
    mapTypeControlOptions: {mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.SATELLITE]}, // here´s the array of controls
    disableDefaultUI: true, // a way to quickly hide all controls
    mapTypeControl: true,streetViewControl: false,fullscreenControl: true, rotateControl: false, mapTypeId: 'roadmap',
    maxZoom:this.maxZoom, minZoom:this.minZoom,
  }
  markers = [] as any;
  infoContent = '';

  slideConfig = {
    slidesToShow: 1, slidesToScroll: 1,infinite: true,autoplay: true,vertical: false,arrows: true,speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows: true,},
      },
      {
        breakpoint: 991,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows: true,},
      },
      {
        breakpoint: 767,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows: true,},
      },
      {
        breakpoint: 480,
        settings: {slidesToShow: 1,slidesToScroll: 1,centerMode: false,arrows: true,},
      },
    ],
  };
  loading: boolean; download: any; id: any; downloadQr: string; isFollowingCurrentList:boolean; userData: any; hideBtn: boolean;

  constructor(private route: ActivatedRoute,private api: ApiService,public session: SessionService,private listingService: ListingService,private networkService: NetworkServiceService,private notificationService: NotificationService,public dialog: MatDialog,private http: HttpClient,private deviceService: DeviceDetectorService,private NgxXml2jsonService: NgxXml2jsonService,private router: Router,private renderer: Renderer2,private el: ElementRef,private location: Location) {
    this.user_id = JSON.parse(sessionStorage.getItem('user-id'));
    this.user = JSON.parse(localStorage.getItem('user'));
    this.route.params.subscribe(param=>{
      if(param.id)this.id = atob(param.id);
    })
    this.getListing(this.id);
    this.currentUrl = window.location.href;
  }

  ngOnInit() { this.getFollowingLists() }

  back() {this.location.back()}

  getFollowingLists(){
    this.api.allFollowing().subscribe((res)=>{
      const allFollowingLists = res[1];
      const followingList = allFollowingLists.filter((i)=>{return i?.id === this.publicList?.id})
      if(followingList.length > 0){this.isFollowingCurrentList = true;}
      else{this.isFollowingCurrentList = false;}
    })
  }

  getListing(data?) {this.loading = true;
    this.api.publicListing(data).subscribe((res: any) => {
      if (res.message === 'Listing is not published for the public' || res.listing.visible == 'no') {
        this.notificationService.showErrorNotification('Qtag is not published for the public', 1000)
        this.notPublish = true; this.loading = false;
        return true;
      } else if (res.listing.visible == 'yes') {res.listing.published = true;}
      this.publicList = res.listing; this.loading = false;
      if (this.user?.id != this.publicList?.user && this.publicList.isApproval != 'pending') {this.hideBtn = true;}
      this.getUserById(this.publicList.user)
      this.downloadQr = this.veiwQRUrl + btoa(this.publicList.id || this.publicList._id)
      this.addressHead = this.publicList.address ? this.publicList.address.split(', ') : []
      this.address1 = this.addressHead[0] ?? ''; this.address2 = this.addressHead[1] ?? ''; this.address3 = this.addressHead[2] ?? ''; this.address4 = this.addressHead[3] ?? ''; this.address5 = this.addressHead[4] ?? '';
      this.photos = res.photos;
      if( this.publicList?.lat || this.publicList?.lng ){
        this.center = {
          lat: Number(this.publicList?.lat),
          lng:  Number(this.publicList?.lng),
        };
        this.dropMarker(this.center)
      }
      else{
        navigator.geolocation.getCurrentPosition((postition) => {
          this.center = {
            lat: postition.coords.latitude,
            lng:  postition.coords.longitude,
          };
          this.dropMarker(this.center)
        })
      }
      if (this.listingService.products.length > 0) {
        var r = this.listingService.products.some(i => i.listingId.includes(this.publicList?.id));
        if (r) {this.addedInCart = true;}
        else {this.addedInCart = false;}
      }
      this.api.getDeviceInfoSaved('listings', this.publicList.id, this.user)
    },error => {this.loading = false;});
  }

  zoomIn() {if (this.zoom < this.maxZoom) this.zoom++;}

  zoomOut() {if (this.zoom > this.minZoom) this.zoom--;}

  eventHandler(event: any, name: string) {
    // Add marker on double click event
    if (name === 'mapDblclick') {}
  }

  // Markers
  logCenter() {}

  dropMarker(event: any) {
    this.markers.push({
      position: {
        lat: event.latLng?.lat() ?? Number(event.lat),
        lng: event.latLng?.lng() ?? Number(event.lng),
      },
      label: {},
      info: this.publicList?.address ?? 'Marker info ' + (this.markers.length + 1),
      options: {animation: google.maps.Animation.DROP},
    });
  }

  openInfo(marker: MapMarker, content: string) {this.infoContent = content;this.info.open(marker);}

  copyInfoUrle(data) {this.networkService.copyInfoUrle(data);this.notificationService.showSuccessNotification('Link copied to clipboard', 1000);}

  openShareOptions(data) {
    data.shareUrl = location.origin + `/qtagQr/`+btoa(`${data?.id ?? data._id}`);
    data.type = 'qtagQr';
    this.dialog.open(ShareOptionsComponent, {
      width: '500px',
      height: '150px',
      data: data,
      disableClose: true
    });
  }

  async downloadCode(e: ListingInfo) {
    this.download = e
    this.downloadQr = this.veiwQRUrl + btoa(e.id || e._id)
    this.loading = true;
    await new Promise(resolve => setTimeout(resolve, 1000));
    this.convertAndDownload()
  }

  async convertAndDownload(): Promise<void> {
    try {
        const content: any = this.el.nativeElement.querySelector('#contentToConvert');
        /*Capture the content as a canvas using html2canvas*/const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
        /*Convert canvas to data URL*/ const imageData = canvas.toDataURL('image/jpeg',0.8);
        /*Create a download link*/ const downloadLink = document.createElement('a');
        downloadLink.href = imageData;
        downloadLink.download = `${this.download?.listingName} (${this.download?.listingUniqueID}).jpg`;
        /*Trigger download immediately*/ downloadLink.click();
        /* Clean up after download*/this.loading = false;
    } catch (error) {
        console.error('Error during conversion and download:', error);
        this.loading = false;
    }
}


  slickInit(e) {}  breakpoint(e) {}  afterChange(e) {}  beforeChange(e) {}

  followButton(id) {
    if(this.user){
      let body = {listing: id._id ?? id.id}
      this.api.followListing(body).subscribe(res => {this.getFollowingLists();})
    }
    else{
      this.notificationService.showErrorNotification('Please login first',1000)
      setTimeout(()=>{this.router.navigate(['login'])},1000)
    }
  }

  unfollowButton(id) {
    let body = {listing: id._id ?? id.id}
    this.api.unfollowListing(body).subscribe(res => {this.getFollowingLists();})
  }

  getUserById(id) {this.api.getUserProfile(id).subscribe((res:any) => {this.userData = res},error => {this.loading = false;})}
  
  navigateChat() {this.router.navigate([`/chat/chat_id/`+btoa(`${this.publicList?.id ?? this.publicList?._id}`) +'/'+ btoa(`listing`)])}

  navigateProfile() {this.router.navigate(['profile/Profile-view',btoa(`${this.userData.uniqueID}`)])}

  addToCart(data: any) {
    if (this.listingService.products.length > 0) {
      var r = this.listingService.products.some(i => i.user !== data.user);
      var listingId = this.listingService.products.some(i => i.listingId == (data.id || data._id));
      if (r) {
        this.notificationService.showErrorNotification('You have an item from another provider in your cart.', 2000);
        return
      }
      else if(listingId) {
        this.notificationService.showErrorNotification('You have already added this product in your cart',2000);
        return;
      }
      else {
        let body = {
          listingId: data.id,
          listingName: data.listingName,
          price: data.price !== "undefined" ? data.price : 0,
          user: data.user,
          select: false,
          quantity: 1,
          createdAt: new Date(),
          percentage: data.percentage,
          estoreName: '',
          estoreOwnerId: '',
          estoreId: ''
        }
        this.listingService.addItemToCart(body);
      }
    }
    else {
      let body = {
        listingId: data.id,
        listingName: data.listingName,
        price: data.price !== "undefined" ? data.price : 0,
        user: data.user,
        select: false,
        quantity: 1,
        createdAt: new Date(),
        percentage: data.percentage,
        estoreName: '',
        estoreOwnerId: '',
        estoreId: ''
      }
      this.listingService.addItemToCart(body);
    }

  }
  addtoCartOption(): boolean {return (!this.addedInCart && this.user?.id !== this.publicList?.user && this.publicList?.price != 'undefined' && this.publicList?.price != '0' && this.publicList?.isApproval !== 'pending')}
}
