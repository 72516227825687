import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';


@Component({
  selector: 'app-view-qrcode',
  templateUrl: './view-qrcode.component.html',
  styleUrls: ['./view-qrcode.component.scss']
})


export class ViewQrcodeComponent {
  veiwUrl:string='https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://dev.myqlout.com/qtagQr/';
  qrCode: any;
  hide:boolean;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any,private el: ElementRef,) {
    this.qrCode = this.veiwUrl + btoa(data?.id)
  }

  async convertAndDownload(): Promise<void> {
    try {
      this.hide = true;
      /*await new Promise(resolve => setTimeout(resolve, 2000));*/ const content: any = this.el.nativeElement.querySelector('#code');
      /*Wait for the content to be fully rendered*/ await new Promise(resolve => setTimeout(resolve, 2000));
      const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
      const imageData = canvas.toDataURL('image/jpeg');
      const downloadLink = document.createElement('a');
      downloadLink.href = imageData;
      downloadLink.download = `${this.data?.listingName}.jpg`;
      downloadLink.addEventListener('click', () => {this.hide = false/*content.remove();*/});
      downloadLink.click();
    } catch (error) {this.hide = false;console.error('Error during conversion and download:', error);}
  }
}
