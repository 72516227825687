import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EshopService } from '@app/eshop/eshop.service';
import { ListingService } from '@app/listing/listing.service';
import { ListingInfo } from '@app/models/listing.model';
import { UserInfoApi } from '@app/models/profile.model';
import { ScanModalComponent } from '@app/scan-modal/scan-modal/scan-modal.component';
import { ApiService } from '@app/services/api.service';
import { ShareOptionsComponent } from '@app/share-options/share-options.component';
import { CreateOptionsComponent } from '@app/user-profile/create-options/create-options.component';
import { ViewQrcodeComponent } from '@app/view-qrcode/view-qrcode.component';
import { error } from 'console';
import html2canvas from 'html2canvas';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-activity-dashboard',
  templateUrl: './activity-dashboard.component.html',
  styleUrls: ['./activity-dashboard.component.scss'],
})
export class ActivityDashboardComponent {
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild('activatelistPopup', { static: false }) activatelistPopup: any;
  ProfileUrl: string = 'https://quickchart.io/qr?text=https://dev.myqlout.com/profile/Profile-view/';
  currentUser: any;userData: any;download: UserInfoApi;listing: ListingInfo[] = [];searchListing: any[] = [];
  Following: any[] = [];Followers: any[] = [];loading: boolean = false;storeLength: any;listLength: number;orderLength: any;
  downloadQr: any;qrCode: string;searchStr: string = '';
  private destroy$: Subject<void> = new Subject();

  constructor(private eshopService: EshopService,private apiService: ApiService,public dialog: MatDialog,private listingService: ListingService,private router: Router) {let r = JSON.parse(localStorage.getItem('user'));this.currentUser = r;}

  ngOnInit() {this.getUserById(this.currentUser.id);this.getListing();this.makeMultipleRequests();this.makeMultipleFollowersRequests();this.getAllEshops();this.getOrderDetails();}

  getAllEshops() {this.eshopService.getAllEshops(true).subscribe((res) => {
    this.storeLength = res.length;
    res.map((i: any) => {
      if (i.to) {
          const currentDate = new Date().getDate();
          const lastDate = new Date(i.to).getDate();
          if (lastDate > currentDate) {
            let body = {publish: i.publish == 'draft',id: i.id};
            this.apiService.updateEstoreStatus(body).subscribe((res: any) => {},error=>{});
          }
      }
      return i;
  })
  },error=>{this.loading=false})
}

  getOrderDetails() {this.apiService.getIncomingOrderList().subscribe((res) => {if (res) {this.orderLength = res.order.length;}},(error) => {this.loading=false});}

  navigate(data) {this.router.navigate([`/qtag/view/` + btoa(`${data.id}`)]);}

  makeMultipleRequests() {
    this.apiService.allFollowing().subscribe((res: any[]) => {
        const uniqueFollowing: any[] = [];
        res.forEach((arr: any) => {
            if (Array.isArray(arr) && arr.length > 1) {
                const filteredArr = arr.slice(0, arr.length - 1); // Exclude the last item
                filteredArr.forEach((item: any) => {
                    if (!uniqueFollowing.some((uItem) => uItem.id === item.id)) {
                        uniqueFollowing.push(item);
                    }
                });
            }
        });
        this.Following = uniqueFollowing;
    },error=>{this.loading=false});
  }

  makeMultipleFollowersRequests() {
    this.apiService.allFollowers().subscribe((res: any[]) => {
        const uniqueFollowers: any[] = [];
        res.forEach((arr: any) => {
            if (Array.isArray(arr)) {
                arr.forEach((item: any) => {
                    if (!uniqueFollowers.some((uItem) => uItem.id === item.id)) {
                        uniqueFollowers.push(item);
                    }
                });
            }
        });
        this.Followers = uniqueFollowers;
    },error=>{this.loading=false});
  }

  openActive() {
    this.dialog.open(this.activatelistPopup, {
      width: '400px',
      height: '250px',
      disableClose: true
    })
  }
  back() {this.dialog.closeAll()}

  openPicturePreview(data: any) {if(data.block == 'yes'){this.openActive();return;}this.dialog.open(ViewQrcodeComponent, {width: '200px',data: data,});}

  getUserById(data?) {this.loading = true;this.apiService.getUserProfile(data).subscribe((res: any) => {this.userData = res;this.loading = false;this.userData.encId = btoa(res.uniqueID);this.qrCode = this.ProfileUrl + btoa(this.userData?.uniqueID)},error=>{this.loading=false});}

  openShareOptions(data) {data.shareUrl = location.origin + `/profile/Profile-view/` + btoa(`${data.uniqueID}`);data.type = 'profileView';this.dialog.open(ShareOptionsComponent, {width: '500px',height: '150px',data: data,disableClose: true,});}

  openListShareOptions(data) {if(data.block == 'yes') {this.openActive();return;} data.shareUrl = location.origin + `/qtagQr/` + btoa(`${data.id}`);data.type = 'qtagQr';this.dialog.open(ShareOptionsComponent, {width: '500px',height: '150px',data: data,disableClose: true,});}

  openScanQrCode(data) {this.dialog.open(ScanModalComponent, {width: '400px',height: '600px',data: data,disableClose: true,});}

  getListing() {
    this.listingService.getAllListings(true).pipe(map((res: any[]) => {
            // Filter and map listings
            return res.map((i: any) => {
              if (i.to) {
                  const currentDate = new Date().getDate();
                  const lastDate = new Date(i.to).getDate();
                  if (lastDate > currentDate) {
                    let body = {id: i.id,visible: 'no'}
                    this.apiService.listingStatusUpdate(body).subscribe((res) => {
                      if (res) {}
                    },error => {this.loading=false})
                  }
              }
              return i;
          }).filter((i) => i.isDelete != 'true' && (i.published === true || i.visible === 'yes') && i?.user != '66307a7d5e50a0499e3c7ad5' && i.block == 'no').sort((a, b) => b.created.localeCompare(a.created));
        })
    ).subscribe((filteredListing: any[]) => {
        // Update listings and searchListing after processing
        this.listLength = filteredListing.length;
        this.listing = filteredListing;
        this.searchListing = filteredListing;
    });
  }

  trackByFn(index, item) {return item?.id;}

  searchList(event) {if (event.target.value) {let d = this.listing.filter((i: any) => {const listName = i.listingName.toLowerCase();const searchStr = event.target.value.toLowerCase();return searchStr === listName || listName.includes(searchStr);});this.searchListing = d;} else {this.searchListing = this.listing;}}

  openCreateOptions() {this.dialog.open(CreateOptionsComponent, {width: '500px',height: 'auto',data: `Let's get Started! What do you want to create today?`,disableClose: true,});}

  async downloadCode(e: UserInfoApi) {this.download = e;this.loading = true;await new Promise(resolve => setTimeout(resolve, 1000));this.convertAndDownload();}

  async convertAndDownload(): Promise<void> {try {const content: any = document.getElementById('contentToConvert');
      /* Wait for the content to be fully rendered*/await new Promise((resolve) => setTimeout(resolve, 2000));
      /* Capture the content as a canvas using html2canvas*/const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
      /* Convert canvas to data URL*/const imageData = canvas.toDataURL('image/jpeg',0.8);
      /* Create a download link*/const downloadLink = document.createElement('a');downloadLink.href = imageData;downloadLink.download = `${this.download?.firstName}_${this.download?.lastName+ '('+ this.download?.uniqueID+')'}.jpg`;
      /* Append the download link to the body*/document.body.appendChild(downloadLink);
      /* Use a timeout to trigger the download*/setTimeout(() => {downloadLink.click();document.body.removeChild(downloadLink); /* Clean up after download*/this.loading = false;}, 100);} catch (error) {console.error('Error during conversion and download:', error);this.loading = false;}
  }
}