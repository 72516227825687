import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SessionService } from './../services/session.service';
import { ApiService } from '@app/services/api.service';
import { Router } from '@angular/router';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { ListingService } from '@app/listing/listing.service';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  walletQRUrl: string = 'https://api.qrserver.com/v1/create-qr-code/?size=80x80&data=https://dev.myqlout.com/'
  @Output() toggleMenu: EventEmitter<boolean> = new EventEmitter();
  userData: any;currentUser: any;chatSocket: any;
  notifications: any[] = [];oldNotifications: any[] = [];chat: any[] = [];getChat: any[] = [];cartlength: any[]=[];
  hidden = false;isMobile: boolean;qrCode: string;

  constructor(private sessionService: SessionService, public apiService: ApiService,public listingService:ListingService,public mediaObserver: MediaObserver,private router: Router) {
    let d = JSON.parse(localStorage.getItem('user'));this.currentUser = d;
    this.apiService.data.subscribe((res:any)=>{
      if(res){
        this.getUserById(d.id)
      }else{
            this.getUserById(d.id)
      }
    })


    this.chatSocket = io.connect(environment.socketUrl);
    this.chatSocket.on('newMessage', (res: any) => {
      this.chat.push(res);
      const key = 'sender_id';
      if(this.chat){
        const arrayUniqueByKey = [...new Map(this.chat.map(item =>
          [item[key], item])).values()];
        this.apiService.chatsBubble = arrayUniqueByKey
      }
    })
    this.listingService.cartItems.subscribe(res =>{
      let cartlength:any;cartlength=res.length;
      this.cartlength = cartlength;
    })
      /*this.cartlength = this.listingService.products.length*/
      this.isMobile = this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm') || this.mediaObserver.isActive('md');
  }

  ngOnInit(): void {
    this.getNotification();this.getChats();
     this.apiService.dataSubject.subscribe((res:any)=>{
      if(res){
      this.apiService.getCart(this.currentUser.id).subscribe((res:any)=>{this.cartlength = res.length})
    }
    })
  }

  ngAfterViewInit() {
    let d = JSON.parse(localStorage.getItem('user'))
    this.currentUser = d;
    this.getUserById(d.id)
  }

  getChats() {
    this.apiService.GetAllRecieverChats().subscribe((res) => {
      if (res) {
        this.getChat=res.notification?.filter(res => res.msgRead == "no");
        const key = 'sender_id';
        if(this.getChat !== undefined)
        {
         const arrayUniqueByKey = [...new Map(this.getChat.map(item =>
          [item[key], item])).values()];
        this.apiService.chatsBubble = arrayUniqueByKey
        }
        else{this.apiService.chatsBubble = []}
      }
    },
      error => {

      })
  }
  getUserById(data?) {this.apiService.getUserProfile(data).subscribe((res: any) => {this.userData = res;this.qrCode = this.walletQRUrl + btoa(this.userData.uniqueID) + '/wallet'})}

  getNotification() {
    let body = {endPage: 1,pageLimit: 5,user: this.currentUser?.id}
    this.apiService.getNotification(body).subscribe((res) => {
      if (res) {this.apiService.notifications = res.notifications;this.oldNotifications = res.Oldnotifications;}
    },error => {})
  }

  readNotification(item) {
    let unreadNotifications = [];unreadNotifications.push(item.id); let data = { ids: unreadNotifications };
    this.apiService.notificationStatusUpdate(data).subscribe((res:any) => {this.getNotification();this.router.navigate(['/network']);}, error => {})
  }

  toggle() {this.toggleMenu.emit();}

  logout() {this.sessionService.logout();sessionStorage.clear()}
}
