<div fxLayout="row class" class="flex-test full-header">
    <mat-icon class="this-meta" fxShow fxHide.gt-md *ngIf="token">menu</mat-icon>
    <img class="logo-1" fxShow fxHide.gt-md src="/assets/logo.svg" loading="lazy" alt="logo" aria-label="logo"/>

    <div class="tr-p">
    </div>
    <div class="right">
        <mat-icon class="greyed-out" [matBadge]="cartlength" [matBadgeHidden]="cartlength?.length == 0" matBadgeColor="primary" routerLink="/cart" matTooltip="Cart">shopping_cart</mat-icon>
        <button [matMenuTriggerFor]="profile" mat-icon-button>
            <img [src]="'assets/images/profilepic2.png'" loading="lazy" alt="user" aria-label="user-image" class="user-image" />
        </button>
        <mat-menu #profile="matMenu">
            <button mat-menu-item (click)="loginToCheckout()"><mat-icon>exit_to_app</mat-icon> Sign In</button>
        </mat-menu>
    </div>
</div>