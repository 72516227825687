import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserInfo, UserInfoApi } from '@app/models/profile.model';
import { ScanModalComponent } from '@app/scan-modal/scan-modal/scan-modal.component';
import { ApiService } from '@app/services/api.service';
import { ShareOptionsComponent } from '@app/share-options/share-options.component';
import { CreateOptionsComponent } from '@app/user-profile/create-options/create-options.component';
import { error } from 'console';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  ProfileUrl:string='https://quickchart.io/qr?text=https://dev.myqlout.com/profile/Profile-view/';
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;  @ViewChild('imageFileInput') imageFileInput!: ElementRef;  @ViewChild('videoFileInput') videoFileInput: ElementRef;
  selectedImage: any;  selectedVideo:any;  currentUser: any;  userData: any;  currentUrl: any;  download: any;
  showDate:boolean = false;  showArticle:boolean = false;  loading:boolean = false;
  Following:any[]=[];  Followers:any[]=[];  downloadQr: string;

  constructor(private apiService:ApiService,public dialog: MatDialog,private renderer: Renderer2,private el: ElementRef) {
    let r = JSON.parse(localStorage.getItem('user')); this.currentUser = r; this.currentUrl = window.location.href;}
  
  ngAfterViewInit() {this.getUserById(this.currentUser.id)}

  ngOnInit() {}

  //open image upload field
  openImageFileInput() {this.showDate = false;this.showArticle = false;this.imageFileInput.nativeElement.click();}
  
  //get the selected image with correct format
  handleImageFileChange(event: any) {
    const file = event.target.files[0];
    if (file && file.type === 'image/jpeg') {
      const reader = new FileReader();
      reader.onload = (e: any) => {this.selectedImage = e.target.result;};reader.readAsDataURL(file);
    } else {/*Handle file type not supported or no file selected*/}
  }

  /*open video upload input field*/
  openVideoFileInput() {this.showDate = false;this.showArticle = false;this.videoFileInput.nativeElement.click();}

  handleVideoFileInput(event: any) { //get the video selected and with the correct format
    const file = event.target.files[0];
    if (file && file.type === 'video/mp4') {/*Process the selected video file*/
      const reader = new FileReader();
      reader.onload = (e: any) => {this.selectedVideo = e.target.result;};
      reader.readAsDataURL(file);/*You can also trigger further actions with the selected file*/
    } else {}
  }

  getUserById(data?) {this.loading = true;this.apiService.getUserProfile(data).subscribe((res:any)=>{this.userData = res;this.loading = false;this.downloadQr = this.ProfileUrl + btoa(this.userData?.uniqueID)},error=>{this.loading=false;})}

  openShareOptions(data) {
  data.shareUrl = location.origin+`/admin/profile/`+btoa(`${data.uniqueID}`);data.type = 'profileView';
  this.dialog.open(ShareOptionsComponent, {width: '500px',height:'150px',data: data,disableClose: true,});
  }

  openScanQrCode(data) {
    this.dialog.open(ScanModalComponent, {width: '400px',height:'600px',data: data,disableClose: true});
  }

  openCreateOptions() {
    this.dialog.open(CreateOptionsComponent, {width: '500px',height:'auto',data: `Let's get Started! What do you want to create today?`,disableClose: true});
  }

  async downloadCode(e: UserInfoApi) {this.download = e;this.loading = true;await new Promise(resolve => setTimeout(resolve, 1000));this.convertAndDownload();}

  async convertAndDownload(): Promise<void> {try {const content: any = document.getElementById('contentToConvert');
      /* Wait for the content to be fully rendered*/await new Promise((resolve) => setTimeout(resolve, 2000));
      /* Capture the content as a canvas using html2canvas*/const canvas = await html2canvas(content, { useCORS: true, allowTaint: true, scale: window.devicePixelRatio, logging: false });
      /* Convert canvas to data URL*/const imageData = canvas.toDataURL('image/jpeg',0.8);
      /* Create a download link*/const downloadLink = document.createElement('a');downloadLink.href = imageData;downloadLink.download = `${this.download?.firstName}_${this.download?.lastName+ '('+ this.download?.uniqueID+')'}.jpg`;
      /* Append the download link to the body*/document.body.appendChild(downloadLink);
      /* Use a timeout to trigger the download*/setTimeout(() => {downloadLink.click();document.body.removeChild(downloadLink); /* Clean up after download*/this.loading = false;}, 100);} catch (error) {console.error('Error during conversion and download:', error);this.loading = false;}
    }
}
