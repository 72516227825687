<div fxLayout="row class" class="flex-test full-header">
    <mat-icon class="this-meta" fxShow fxHide.gt-md (click)="toggle()">menu</mat-icon>
    <img class="logo-1" fxShow fxHide.gt-md src="/assets/logo.svg" [routerLink]="'/'" alt="logo" aria-label="logo" loading="lazy"/>

    <div class="tr-p">
    </div>
    <div class="right">
        <mat-icon class="greyed-out" [matBadge]="apiService.notifications?.length" [matBadgeHidden]="apiService.notifications?.length == 0" matBadgeColor="primary" mat-button [matMenuTriggerFor]="notification" style="cursor: pointer;" matTooltip="Notification">notifications_active</mat-icon>
            <ng-container>
                <mat-menu #notification="matMenu">
                    <ng-container *ngIf="apiService.notifications?.length">
                        <div mat-menu-item class="container" style="max-width: 100% !important;">
                            <mat-card class="container notification">
                                <mat-list class="set_card">
                                    <mat-list *ngFor="let notif of apiService.notifications.slice(0, 3)">
                                        <mat-list-item>
                                            <div matListItemLine>
                                                <mat-icon class="icns ">{{notif.type == 'estore' ? 'store' : notif.type == 'listing' ? 'listing' : 'person' }}
                                                </mat-icon>
                                                <div class="fix-p" *ngIf="notif.type=='estore'" (click)="readNotification(notif)"><b>{{notif.senderName}}</b> is now following your estore <b>{{notif.estoreName}}</b>.</div>
                                                <div class="fix-p" *ngIf="notif.type== 'listing'" (click)="readNotification(notif)"><b>{{notif.senderName}}</b> is now following your Qtag <b>{{notif.listingName}}</b>.</div>
                                                <div class="fix-p" *ngIf="notif.type=='user'" (click)="readNotification(notif)"><b>{{notif.senderName}}</b> is now following you.</div>
                                                <div class="fix-p" *ngIf="notif.type=='vender'" (click)="readNotification(notif)">You got a new order from <b>{{notif.senderName}}</b></div>
                                                <div class="fix-p" *ngIf="notif.type=='orderCancelRequest'" (click)="readNotification(notif)"><b>{{notif.senderUniqeId}}</b> wants to cancel your order <b>{{notif.orderId}}</b></div>
                                            </div>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </mat-list>
                                </mat-list>
                                <!-- *ngIf="notifications.length > 3" -->
                                <mat-list class="set_card" style="justify-content: center; display: flex;">
                                    <button mat-raised-button color="primary" routerLink="/notification">View All Notifications</button>
                                </mat-list>
                            </mat-card>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="oldNotifications?.length && !apiService.notifications?.length">
                        <div mat-menu-item class="container" style="max-width: 100% !important;">
                            <mat-card class="container">
                                <mat-list class="set_card">
                                    <mat-list>
                                        <mat-list-item>
                                            <div matListItemLine>
                                                <div class="fix-p">No new notification found for you.</div>
                                            </div>
                                        </mat-list-item>
                                        <mat-divider></mat-divider>
                                    </mat-list>
                                </mat-list>
                                <!-- *ngIf="notifications.length > 3" -->
                                <mat-list class="set_card" style="justify-content: center; display: flex;">
                                    <button mat-raised-button color="primary" routerLink="/notification">View All Notifications</button>
                                </mat-list>
                            </mat-card>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!apiService.notifications?.length && !oldNotifications?.length">
                        <button mat-menu-item> No notification found for you.</button>
                    </ng-container>
                </mat-menu>
            </ng-container>
        <mat-icon class="greyed-out" [matBadge]="apiService.chatsBubble?.length " [matBadgeHidden]="apiService.chatsBubble?.length == 0" matBadgeColor="primary" routerLink="/chat" style="cursor: pointer;" matTooltip="Chat" *ngIf="currentUser.role != 'Admin'">chat_bubble</mat-icon>
        <mat-icon class="greyed-out" [matBadge]="cartlength" [matBadgeHidden]="cartlength.length == 0" matBadgeColor="primary" routerLink="/cart" style="cursor: pointer;" *ngIf="currentUser.role == 'User'" matTooltip="Cart">shopping_cart</mat-icon>
        <mat-icon class="greyed-out" [matBadge]="0" [matBadgeHidden]="0" matBadgeColor="primary" mat-button [matMenuTriggerFor]="wallet" style="cursor: pointer;" matTooltip="Wallet">account_balance_wallet</mat-icon>
            <ng-container>
                <mat-menu #wallet="matMenu">
                        <div mat-menu-item class="container" style="max-width: 100% !important;min-height: auto !important;">
                            <mat-card class="container">
                                <div class="wallet custom-bt-23">
                                    <p>Account Balance: {{this.userData?.wallet ?? 0 |currency : 'CAD' : 'code'}}</p>
                                    <p *ngIf="currentUser.role == 'Admin'">Locked Amount: {{this.userData?.blockAmount || 0 |currency:'CAD':'code'}}</p>
                                    <!-- <img src="{{qrCode}}" alt="wallet qr" loading="lazy" class="qrImg"> -->
                                    <a mat-raised-button color="primary" routerLink="/wallet">Wallet Details</a>
                                </div>
                            </mat-card>
                        </div>
                </mat-menu>
            </ng-container>
        <button [matMenuTriggerFor]="profile" mat-icon-button>
            <img [src]="userData?.profilePicture || 'assets/images/profilepic2.png'" loading="lazy" alt="profilePicture" class="user-image" />
        </button>
        <mat-menu #profile="matMenu">
            <button mat-menu-item [routerLink]="currentUser.role == 'User' ? '/profile' : 'admin/profile'"><mat-icon>account_box</mat-icon> Profile</button>
            <button mat-menu-item (qClickKeydown)="logout()"><mat-icon>exit_to_app</mat-icon> Sign Out</button>
        </mat-menu>
    </div>
</div>