<div class="user-dasboard">
    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-heading">
                <h3>Dashboard</h3>
            </div>
        </div>
    </div>
    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-white">
                <div class="demo-grid-1 mdl-grid">
                    <div class="mdl-cell mdl-cell--4-col">
                        <div class="content-top-white-1">
                            <div class="con-1">
                                <img src="{{userData?.profilePicture || 'assets/images/profilepic2.png'}}" class="eshop_banner_98" alt="profilePic" aria-label="profilePic" loading="lazy">
                                <p class="MyProfile">{{ userData?.firstName }} {{ userData?.lastName }}</p>
                            </div>
                           <div class="ProfileHeadline-div">
                            <p class="ProfileHeadline">{{userData?.uniqueID ?? 'SCGG12566' |titlecase}}</p>
                            <p class="ProfileHeadline-1">{{userData?.title ?? 'Profile Headline' |titlecase}}</p>
                           </div>
                        </div>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col">
                        <div class="content-top-white-2">
                            <div class="bar-code">
                                <img src="{{qrCode}}" class="eshop_banner_981" alt="qrCode" aria-label="qrCode" loading="lazy">
                                <a href="javascript:void(0)" (qClickKeydown)="openShareOptions(userData)"><i class="fa-solid fa-share"></i> Share</a>
                            </div>
                            <div class="bar-code-1">
                                <ul>
                                    <li><button (qClickKeydown)="downloadCode(userData)" [disabled]="loading" style="cursor: pointer;">Download</button></li>
                                    <li><button class="active" (qClickKeydown)="openScanQrCode(userData)">Scan QR</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mdl-cell mdl-cell--4-col">
                        <div class="content-top-white-3">
                            <ul>
                                <li routerLink="/network">
                                    <p>{{Following?.length ?? '0'}}</p>
                                    <button>Following</button>
                                </li>
                                <li routerLink="/network">
                                    <p>{{Followers?.length ?? '0'}}</p>
                                    <button>Follower</button>
                                </li>
                            </ul>
                            <ul>
                                <li routerLink="/incoming-orders">
                                    <p>{{orderLength ?? '0'}}</p>
                                    <button>Orders</button>
                                </li>
                                <li routerLink="/eshop">
                                    <p>{{storeLength ?? '0'}}</p>
                                    <button>E-shop</button>
                                </li>
                                <li routerLink="/qtag">
                                    <p>{{listLength ?? '0'}}</p>
                                    <button>Qtag</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="demo-grid-1 mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="content-top-heading-5656">
               <div class="one-hed">
                    <div class="one-hed-left">
                        <button routerLink="/analytics">Analytics</button>
                        <button routerLink="/chat">Messages</button>
                    </div>
                    <div class="one-hed-right">
                        <button (qClickKeydown)="openCreateOptions()" style="cursor: pointer;">Create</button>
                        <div class="input-hd">
                            <input type="search" placeholder="search" [(ngModel)]="searchStr" [ngModelOptions]="{ standalone: true }" (keyup)="searchList($event)">
                            <i class="fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
               </div>
               <div class="table-td">
                <table>
                    <thead>
                        <tr>
                            <th>My Qtags</th>
                            <th><button class="manage-bt" routerLink="/qtag">Manage</button></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of searchListing; trackBy: trackByFn">
                            <td>
                                <div class="manage-td">
                                    <img src="{{userData?.profilePicture || 'assets/images/eshop_banner.png'}}" alt="profilePic" aria-label="profilePic" loading="lazy">
                                    <div><p class="lis-1" (click)="navigate(item)">{{ item?.listingName ?? 'Qtag 1 - Quickex' }}</p><p class="lis-2">{{ item?.description ?? 'Qtag 1 - Quickex' }}</p></div>
                                </div>
                            </td>
                            <td>
                                <div class="main-pd">
                                    <button class="manage-bt sm-bt" (qClickKeydown)="openPicturePreview(item ?? 'assets/images/barcode.png')">View QR</button>
                                    <button class="manage-bt sm-bt" (qClickKeydown)="openListShareOptions(item)">Share</button>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="listing.length <= 0 || !searchListing.length"><td>No Data Found</td></tr>
                    </tbody>
                </table>
               </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="loading">
    <div class="spin">
      <mat-spinner>Downloading...</mat-spinner>
    </div>
</ng-container>

 <div class="contBlock" #contentToConvert id="contentToConvert" style="display: flex;flex-direction: column;align-items: center;margin-top: -200000px; position: absolute;padding: 10px 0;">
    <img src="assets/logo.png" alt="logo" aria-label="logo" style="max-width: 100px;margin-bottom: 10px;"/>
    <img src="{{ProfileUrl + download?.encId}}" alt="qrCode" aria-label="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)">{{download?.firstName + ' ' + download?.lastName |titlecase}} ({{download?.uniqueID}})</a>
</div>

<!-- <div class="contBlock" #contentToConvert id="contentToConvert" style="position: absolute; width: 60%; margin-top:-20000px;">
    <img src="assets/logo.png" style="max-width: 100px;margin-bottom: 10px;"/>
    <img src="{{ProfileUrl + download?.encId}}" alt="qrCode" class="qrimg">
    <a class="qrName" href="javascript:void(0)" *ngIf="download">{{download?.firstName + ' ' + download?.lastName |titlecase}} ({{download.uniqueID}})</a>
</div> -->

<!--------------------------Qtag Not Active modal--------------------------->
<ng-template #activatelistPopup let-modal>
    <div class="popup">
      <div class="modal-header right-fix">
        <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
        <button mat-button mat-dialog-close><span>&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="wallet-activate-msg">
          <p>Your Qtag has been blocked by the admin.
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button mat-raised-button color="dark" class="submit_btn mr-1" type="button" (click)="back()">Ok</button>
      </div>
    </div>
  </ng-template>